<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-progress-linear
          v-if="carregando"
          indeterminate
          color="#70445E"
        ></v-progress-linear>
        <v-container>
          <v-row class="mt-5">
            <v-col cols="12" class="my-0 py-0">
              <v-btn outlined class="elevation-0" @click="Voltar()">
                🡠
              </v-btn>
            </v-col>
            <v-col cols="12">
              <span class="caption">{{ new Date(post.createdAt).toLocaleString() }}</span>
              <p class="text-h3 laubeFont" style="color: #70445E;">
                {{ post.title }}<br />
              </p>
              <v-img :src="post.imageUrl" contain max-height="400" max-width="400" style="margin: 0 auto;"></v-img>
              <div class="mt-12" v-html="post.body"></div>
            </v-col>
            <v-col cols="2" offset="10">
              <span class="laubeFont caption"><i>por Daniela Laubé</i></span>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <v-progress-linear
          v-if="carregando"
          indeterminate
          color="#70445E"
        ></v-progress-linear>
        <div class="container" id="topoBlog" style="margin-bottom: 20vh;">
          <v-row class="align-start mt-5">
            <v-col cols="12" class="my-0 py-0">
              <v-btn outlined class="elevation-0" @click="Voltar()">
                🡠
              </v-btn>
            </v-col>
            <v-col cols="12">
              <span class="caption">{{ new Date(post.createdAt).toLocaleString() }}</span>
              <p class="text-h3 laubeFont" style="color: #70445E;">
                {{ post.title }}<br />
              </p>
              <v-img :src="post.imageUrl" contain max-height="300" max-width="300" style="margin: 0 auto;"></v-img>
              <div class="mt-10" v-html="post.body"></div>
            </v-col>
            <v-col cols="5" offset="7">
              <span class="laubeFont caption"><i>por Daniela Laubé</i></span>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
// import $ from 'jquery'
import axios from 'axios'
import AppBar from '../components/AppBar.vue'
export default {
  name: 'BlogPost',
  components: {
    AppBar
  },
  data () {
    return {
      carregando: false,
      post: {},
    }
  },
  mounted () {
    if (this.$route.params != null)
      this.buscaPost(this.$route.params.id)
    else
      window.location.href = './'
  },
  methods: {
    buscaPost (id) {
      this.carregando = true
      axios.get('https://laubeapi.azurewebsites.net/api/Blog/Buscar/' + id)
      // axios.get('https://localhost:44303/api/Blog/Buscar/' + id)
      .then((r) => {
        this.post = r.data
        this.carregando = false
      })
      .catch((e) => {
        console.log(e)
      })
    },
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
    Voltar () {
      this.$router.push({path: '/blog'});
    }
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
</style>
